@font-face {
    font-family: 'Ubuntu';
    font-display: swap;
    font-style: normal;
    src: local('Ubuntu Regular'),
    url('../fonts/Ubuntu/Ubuntu.woff2') format('woff2'),
    url('../fonts/Ubuntu/Ubuntu.woff') format('woff'),
    url('../fonts/Ubuntu/Ubuntu.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Ubuntu';
    font-display: swap;
    font-style: normal;
    src: local('Ubuntu Light'),
    url('../fonts/Ubuntu/UbuntuLight.woff2') format('woff2'),
    url('../fonts/Ubuntu/UbuntuLight.woff') format('woff'),
    url('../fonts/Ubuntu/UbuntuLight.ttf') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'Ubuntu';
    font-display: swap;
    font-style: normal;
    src: local('Ubuntu Medium'),
    url('../fonts/Ubuntu/UbuntuMedium.woff2') format('woff2'),
    url('../fonts/Ubuntu/UbuntuMedium.woff') format('woff'),
    url('../fonts/Ubuntu/UbuntuMedium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Ubuntu';
    font-display: swap;
    font-style: normal;
    src: local('Ubuntu Bold'),
    url('../fonts/Ubuntu/UbuntuBold.woff2') format('woff2'),
    url('../fonts/Ubuntu/UbuntuBold.woff') format('woff'),
    url('../fonts/Ubuntu/UbuntuBold.ttf') format('truetype');
    font-weight: 700;
}
