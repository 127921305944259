.breadcrumb {
    display: inline-flex;
    justify-content: center;
    padding: 0;

    background: transparent;

    &-item {
        font-size: 14px;
        color: rgba(#fff, .8);

        a {
            color: inherit;

            &:hover {
                color: #fff;
            }
        }

        & + &:before {
            color: rgba(#fff, .8);
            content: ">";
        }
    }
}