// -------------------------------------
//   blog-row
// -------------------------------------
.blog-row {
    display: flex;

    @include media-breakpoint-down(md) {
        flex-wrap: wrap;
    }

    &__img {
        display: block;
        flex: 0 0 50%;
        max-width: 50%;
        min-height: 400px;

        background: center / cover no-repeat;

        @include media-breakpoint-down(md) {
            flex: 1;
            max-width: 100%;
            min-height: 1px;
            padding-bottom: 50%;
        }
    }

    &__body {
        padding: 60px 100px;

        @include media-breakpoint-down(lg) {
            padding: 30px;
        }
    }

    &__head {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 60px;
    }

    &__category {
        font-size: 12px;
        color: $primary;
        text-transform: uppercase;
        font-weight: 500;
    }

    &__title {
        margin-bottom: 30px;

        font-size: 25px;
        color: $dark;

        a {
            color: inherit;
        }
    }

    &__text {
        font-size: 14px;
        line-height: 2.2;
        color: $grey;

        max-height: 1em * 2.2 * 3;
        overflow: hidden;
    }

    &__info {
        text-align: right;
    }

    &-info {
        display: inline-block;
        margin: 0 6px 6px 6px;

        &__value {
            vertical-align: middle;
        }
    }

    &__blog-page-author {

        @include media-breakpoint-down(xs) {
            .blog-page-author__img {
                display: none;
            }
        }
    }

    &__read-more {

    }

    &_right {

        .blog-row__body {
            order: 1;

            @include media-breakpoint-down(md) {
                order: 2;
            }
        }

        .blog-row__img {
            order: 2;

            @include media-breakpoint-down(md) {
                order: 1;
            }
        }
    }
}
