// -------------------------------------
//   modal
// -------------------------------------

.modal {
    .modal-header {
        padding-right: 35px;
    }

    .close {
        position: absolute;
        right: 12px;
        top: 20px;
        padding: 0;
        margin: -10px 0 0;
        font-size: 0;
        width: 20px;
        height: 20px;

        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 50%;
            margin-top: -1px;
            display: block;
            width: 100%;
            height: 2px;
            background: #888;
        }

        &:before {
            transform: rotateZ(45deg);
        }

        &:after {
            transform: rotateZ(-45deg);
        }
    }
}
