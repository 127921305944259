// -------------------------------------
//   widget-card
// -------------------------------------

.widget-card {
    $block: &;

    position: relative;
    z-index: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 50px;
    margin: 0 -1px 0 0;

    text-align: center;

    background: #fff;

    &_dark {
        color: #fff;

        background: $dark;

        #{$block} {

            &__title {
                color: #fff;
            }

            &__link {
                color: #fff;

                &:hover {
                    color: #aaa;
                }
            }
        }
    }

    &__icon-place {
        width: 50px;
        height: 50px;
        margin-bottom: 25px;

        color: $primary;
        font-size: 50px;
        line-height: 1;
    }

    &__icon {
        max-width: 100%;
        max-height: 100%;
    }

    &__title {
        max-width: 100%;
        margin-bottom: 15px;

        font-size: 18px;
        color: $dark;
        text-transform: uppercase;
    }

    &__text {
        max-width: 100%;
    }

    &__link {
        margin-top: 30px;
        color: $primary;

        &:hover {
            color: $dark;
        }
    }
}