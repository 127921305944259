// -------------------------------------
//   deal-of-day
// -------------------------------------

.deal-of-day {
    $block: &;

    display: flex;

    &__main {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1 1 auto;

        background: $section-grey center / cover no-repeat;

        min-height: 750px;

        @include media-breakpoint-down(xl) {
            min-height: 650px;
        }

        @include media-breakpoint-down(lg) {
            min-height: 550px;
        }
        
        @include media-breakpoint-down(sm) {
            min-height: 450px;
        }
    }

    &__content {
        max-width: 580px;
        margin: auto 0;
        padding: 50px;

        text-align: center;
    }

    &__ps {
        margin-bottom: 20px;

        font-size: 12px;
        color: $grey;
        text-transform: uppercase;
    }

    &__title {
        margin-bottom: 20px;

        font-size: 36px;
        line-height: 1.1;
        font-weight: 700;
        color: $dark;

        @include media-breakpoint-down(lg) {
            font-size: 27px;
        }
        
        @include media-breakpoint-down(xs) {
            font-size: 24px;
        }
    }

    &__text {
        font-size: 18px;
        color: $grey-dark;

        @include media-breakpoint-down(lg) {
            font-size: 16px;
        }
    }

    &-prices {
        margin-top: 35px;

        @include media-breakpoint-down(sm) {
            margin-top: 25px;
        }

        &__new {
            display: inline-block;
            margin: 5px 20px 5px 0;

            font-size: 48px;
            line-height: 1;
            font-weight: 900;
            color: $primary;

            @include media-breakpoint-down(lg) {
                font-size: 36px;
            }
            
            @include media-breakpoint-down(xs) {
                font-size: 30px;   
            }
        }

        &__old {
            display: inline-block;
            margin: 5px 0 5px 0;

            font-size: 30px;
            line-height: 1;
            font-weight: 700;
            color: $dark;
            text-decoration: line-through;

            @include media-breakpoint-down(lg) {
                font-size: 24px;
            }
            
            @include media-breakpoint-down(xs) {
                font-size: 20px;
            }
        }
    }

    &__timer {
        margin-top: 50px;
        
        @include media-breakpoint-down(sm) {
            margin-top: 25px;
        }
    }

    &__view {
        flex: 0 0 50%;
        background: center / cover no-repeat;
        
        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}