// -------------------------------------
//   dadata suggestions
// -------------------------------------

.suggestions {

    &-wrapper {

    }

    &-addon {

        &[data-addon-type=clear] {
            background-size: 55% 55% !important;
        }
    }

    &-suggestions {
        left: 0 !important;
        width: 100% !important;

        margin-top: 5px !important;
        border-radius: 12px !important;
        border: 0 !important;
        box-shadow: 3px 6px 15px 0 rgba(0, 0, 0, 0.1) !important;

        &:before {
            content: "";

            position: absolute;
            bottom: 100%;
            left: 22px;

            border: 8px solid transparent;
            border-top: 0;
            border-bottom-color: #fff;
        }
    }

    &-suggestion {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    &-hint {
        padding-left: 10px !important;
    }
}
