// -------------------------------------
//   shine
// -------------------------------------

.shine {
    position: relative;
    overflow: hidden;

    &:before {
        position: absolute;
        top: 0;
        left: -45%;
        z-index: 2;
        display: block;
        content: '';
        width: 25%;
        height: 100%;
        opacity: 0;
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, .3) 100%);
        transform: skewX(-25deg);
    }

    &:hover {

        &:before {
            animation: shine .5s;
            opacity: .5;
        }
    }
}

@keyframes shine {
    100% {
        left: 125%;
    }
}

.blink {

    &:hover {
        animation: flash .8s;
    }
}

@keyframes flash {
    0% {
        opacity: .55;
    }
    100% {
        opacity: 1;
    }
}