// -------------------------------------
//   good-card
// -------------------------------------
.good-cards {

    &__row {
        margin: -1px 0 0 0;
    }

    &__col {
        padding: 0;
        margin: 0 0 -1px 0;
    }
}

.good-card {
    position: relative;
    z-index: 1;

    height: 100%;
    padding: 50px;
    margin: 0 -1px 0 0;

    background: #fff;
    box-shadow: 0 0 0 1px $border-color inset;

    @include media-breakpoint-down(md) {
        padding: 30px;
    }

    &:hover {
        z-index: 2;
        box-shadow: 0 0 20px 0 rgba(#000, .1);
    }

    &_with-cover {

        @at-root html:not(.mobile):not(.tablet) &:hover {
            .good-card__title {
                color: #fff;

                &:hover {
                    color: rgba(#fff, .9);
                }
            }

            .good-card__price {
                color: #fff;
            }

            .good-card__cover {
                visibility: visible;
                opacity: 1;

                transition: opacity 300ms ease, visibility 0s 0ms;
            }
        }
    }

    &__view {
        position: relative;

        display: block;
        margin-bottom: 25px;

        text-align: center;

        padding-bottom: 75%;
    }

    &__img-place {
        position: absolute;
        top: 0;
        left: 0;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    &__img {
        display: inline-block;
        max-width: 100%;
        max-height: 100%;
    }

    &__info {
        position: relative;
        z-index: 3;
    }

    &__title {
        display: block;
        margin-bottom: 5px;

        font-size: 14px;
        font-weight: 700;
        color: $dark;

        &:hover {
            color: $primary;
        }
    }

    &__price {
        font-size: 14px;
        font-weight: 600;
        color: $primary;
    }

    &__cover {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        width: 100%;
        height: 100%;

        background: rgba($good-card-cover-bg-color, .95);

        visibility: hidden;
        opacity: 0;

        transition: opacity 300ms ease, visibility 0s 300ms;
    }

    &__cover-content {
        display: flex;
        height: 100%;
        width: 100%;
        padding: 50px 0;

        justify-content: center;
        align-items: center;

        @include media-breakpoint-down(md) {
            padding: 30px 0;
        }
    }

    &-meta {
        position: absolute;
        top: 30px;
        right: 0;
        z-index: 1;

        width: 75%;
        padding: 0 30px;

        @include media-breakpoint-down(md) {
            top: 15px;
            padding: 0 15px;
        }

        &__badges {
            text-align: right;
        }

        .good-badge {
            margin-bottom: 3px;
        }
    }

    &__category {
        position: absolute;
        left: 50px;
        top: 50px;

        padding-right: 30px;

        color: #fff;
        font-weight: 500;
        letter-spacing: .1em;

        text-transform: uppercase;

        @include media-breakpoint-down(md) {
            left: 30px;
            top: 30px;
        }

        &:hover {
            color: #fff;

            opacity: .9;
        }
    }

    &-actions {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        min-width: calc(100% - 100px);

        &__left {
            flex-shrink: 0;
            margin-right: 15px;
        }

        &__right {
            text-align: right;
        }

        @include media-breakpoint-down(md) {
            min-width: calc(100% - 60px);
        }
    }

    &__btn {
        padding: .6em 1.1em;

        font-size: 14px;
        color: $dark;

        background: #fff;

        box-shadow: 0 2px 10px 0 rgba($primary, 0.25);

        &:hover {
            background-color: darken(#fff, 8%);
        }

        &:active {
            box-shadow: 0 1px 4px 0 rgba($primary, 0.25);
        }

        &.active {
            color: #fff;

            background: lighten($primary, 6.2%);
        }

        &_buy {

        }

        &_icon {
            min-width: 2.85em;
            padding-left: .6em;
            padding-right: .6em;
        }

        &_compare {
            margin-top: 5px;
            margin-bottom: 5px;
        }

        &_favorite {
            margin-top: 5px;
            margin-bottom: 5px;
            margin-left: 10px;
        }
    }

}

@at-root html.mobile,
html.tablet {
    .good-card {
        padding-bottom: 50px;

        .good-card__cover {
            position: static;

            height: auto;

            background: transparent;

            visibility: visible;
            opacity: 1;
        }

        .good-card__cover-content {
            padding: 0;
            margin-bottom: 15px;
        }

        .good-card__btn_buy {
            background: $primary;
            color: #fff;

            &:hover,
            &.active {
                color: $dark;

                background: #fff;
            }
        }


        .good-card__category {
            color: $primary;
            top: auto;
            bottom: 15px;
            left: 0;

            max-width: 100%;
            padding: 0 30px;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:hover {
                color: $primary;
            }
        }

        .good-card-actions {
            min-width: 0;
            width: 100%;
        }
    }
}
