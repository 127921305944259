// -------------------------------------
//   good-page
// -------------------------------------
.good-page {

    &__category {
        display: inline-block;
        margin-bottom: 10px;

        font-size: 12px;
        letter-spacing: .15em;
        font-weight: 500;
        color: $primary;
    }

    &__title {
        margin-bottom: 20px;

        font-size: 30px;
        font-weight: 400;
        color: $dark;

        @include media-breakpoint-down(sm) {
            font-size: 23px;
        }
    }

    &-intro {
        padding: 80px 115px;

        @include media-breakpoint-down(xl) {
            padding: 60px;
        }

        @include media-breakpoint-down(md) {
            padding-top: 30px;
        }

        @include media-breakpoint-down(sm) {
            padding-left: 30px;
            padding-right: 30px;
        }

        &__row {
            display: flex;

            @include media-breakpoint-down(md) {
                flex-wrap: wrap;
            }
        }

        &__col {
            flex: 0 0 50%;
            max-width: 50%;

            @include media-breakpoint-down(md) {
                flex-basis: 100%;
                max-width: 100%;
            }

            &_info {
                padding-top: 60px;
                padding-right: 80px;

                @include media-breakpoint-down(xl) {
                    padding-right: 30px;
                    padding-top: 30px;
                }

                @include media-breakpoint-down(md) {
                    order: 2;
                    padding-right: 0;
                }
            }

            &_view {
                @include media-breakpoint-down(md) {
                    order: 1
                }
            }
        }

        &__variants {
            margin-bottom: 35px;

            @include media-breakpoint-down(sm) {
                margin-bottom: 15px;
            }
        }

        &__delimiter {
            height: 1px;
            margin: 30px 0;

            background: $border-color;
        }

        &-review {
            display: flex;
            align-items: center;

            margin-bottom: 25px;

            &__view {
                margin-right: 40px;
                line-height: 1
            }

            &__text {
                display: inline-block;

                font-size: 12px;
                line-height: 1.1;
                color: #bbb;

                border-bottom: 1px dotted currentColor;
            }
        }
    }

    &__content {
        padding: 80px 115px;

        @include media-breakpoint-down(xl) {
            padding: 60px;
        }

        @include media-breakpoint-down(md) {
            padding-top: 30px;
            padding-bottom: 30px;
        }

        @include media-breakpoint-down(sm) {
            padding-left: 30px;
            padding-right: 30px;
        }

        &_grey {
            background: $section-grey;
        }
    }

    &-price {
        display: inline-block;
        margin: 10px 15px 10px 0;

        vertical-align: middle;

        &__value {
            display: inline-block;
            margin-right: 5px;

            font-size: 20px;
            line-height: 1;
            color: $dark;
        }

        &__old {
            display: inline-block;

            font-size: 14px;
            color: $grey;
            text-decoration: line-through;
        }
    }

    &__buy-btn {
        font-size: 14px;
        height: 50px;
        width: 100%;
        max-width: 130px;

        border-radius: 100px;
    }

    &-action {
        display: inline-block;
        margin-right: 35px;
        font-size: 12px;

        color: $grey-dark;

        &:hover,
        &.is-active {
            color: $secondary;
        }

        &__icon {
            margin-right: 10px;
            font-size: 1.5em;
        }

        &__name {
            display: inline-block;
            vertical-align: middle;
        }
    }
}

.good-page-variant {

    &__title {
        margin-bottom: 20px;

        font-size: 12px;
        font-weight: 700;
        color: $dark;
        letter-spacing: 0.1em;
    }

    &__body {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;
    }

    &__item {
        display: inline-block;
        margin: 0 5px 10px;
    }


    &__size {
        display: inline-block;
        height: 30px;

        font-size: 14px;
        font-weight: 700;
        line-height: 30px;
        color: $grey-dark;

        &:hover,
        &.is-active {
            color: $secondary;
        }
    }
}

.good-page-slider {
    position: relative;

    padding: 0 120px 0 0;

    @include media-breakpoint-down(lg) {
        padding-right: 80px;
    }

    @include media-breakpoint-down(md) {
        padding-right: 0;
    }

    &-main {
        text-align: center;

        &:not(.slick-initialized) {
            display: none;
        }

        &__wrapper {
            max-width: 500px;
            height: 500px;

            overflow: hidden;

            @include media-breakpoint-down(md) {
                height: 270px;
                max-width: 100%;
                margin-bottom: 0;
                padding-bottom: 30px;
            }
        }

        &-item {
            position: relative;

            height: 500px;

            line-height: 497px;

            @include media-breakpoint-down(md) {
                height: 240px;

                line-height: 240px;
            }
        }

        &-item__img {
            display: inline-block !important;
            width: auto !important;
            max-width: 100%;
            max-height: 100%;

            vertical-align: middle;
        }

        &__fancy {
            display: block;
            height: 100%;

            line-height: inherit;

            &:before {
                content: "";

                position: absolute;
                top: 50%;
                left: 50%;

                display: block;
                height: 50px;
                width: 50px;
                margin-left: -25px;
                margin-top: -25px;

                border-radius: 50%;
                background: $primary url('../img/sprites/sprite.svg#search_white') left 10px top 10px / 27px 27px no-repeat;

                opacity: 0;
                transition: opacity 150ms linear;
            }

            &:hover {

                &:before {
                    opacity: 1;
                }
            }
        }

        .slick {

            &-dots {
                position: absolute;
                bottom: -35px;
                left: 0;
                right: 0;
                z-index: 2;

                padding: 0 15px;
                margin: 0;

                text-align: center;

                li {
                    position: relative;

                    display: inline-block;
                    height: 8px;
                    width: 8px;
                    margin: 4px;

                    border-radius: 50%;

                    background: #b9b9b9;

                    &:before {
                        content: "";

                        position: absolute;
                        top: -4px;
                        left: -4px;
                        right: -4px;
                        bottom: -4px;
                    }

                    button {
                        display: none;
                    }

                    &.slick-active {
                        background-color: $primary;
                    }
                }
            }

            &-arrow {
                position: absolute;
                top: 50%;
                z-index: 2;

                width: 42px;
                height: 42px;

                margin-top: -21px;

                font-size: 0;
                line-height: 42px;
                color: #333;

                border-radius: 50%;
                border: 0;
                background: transparent;

                &:before,
                &:after {
                    content: "";

                    position: absolute;
                    top: 50%;
                    left: 15px;

                    width: 10px;
                    height: 2px;

                    background: currentColor;

                    transform-origin: right center;
                }

                &:before {
                    transform: rotateZ(-45deg);
                }

                &:after {
                    transform: rotateZ(45deg);
                }

                &:hover {
                    background-color: $primary;

                    &:not(.slick-disabled) {
                        color: #fff;
                    }

                    &.slick-disabled {
                        background-color: #eee;
                    }
                }

                button {
                    display: none;
                }

            }

            &-prev {
                left: 0;
                transform: scaleX(-1);
            }

            &-next {
                right: 0;
            }
        }
    }


    &__more {
        position: absolute;
        top: 80px;
        right: 0;

        display: none;
        width: 60px;
        height: 340px;

        @include media-breakpoint-up(lg) {
            display: block;
        }
    }

    &__thumbs-wrapper {
        max-height: 340px;
        overflow: hidden;
    }

    &-thumbs {
        padding: 0;
        margin: 0;
        list-style: none;

        &:not(.slick-initialized) {
            display: none;
        }

        &__item {
            position: relative;
            display: block;
            width: 60px !important;
            height: 60px !important;
            padding-left: 0;

            line-height: 54px;
            list-style: none;
            text-align: center;

            border: 2px solid #fff !important;

            transition: $border-color ease-out .2s;
            vertical-align: bottom;
            cursor: pointer;
            overflow: hidden;

            &.active {
                z-index: 1;

                box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
                border-color: $primary !important;
            }
        }

        &__img {
            display: inline-block !important;
            width: auto !important;
            max-width: 100%;
            max-height: 100%;
            vertical-align: middle;
        }

        .slick {

            &-arrow {
                position: relative;
                top: 50%;
                z-index: 2;

                display: block;
                height: 20px;
                width: 100%;

                font-size: 0;
                line-height: 20px;
                color: #333;

                border: 0;
                background: transparent;

                &:before,
                &:after {
                    content: "";
                    position: absolute;
                    left: 50%;
                    top: 4px;

                    width: 2px;
                    height: 10px;

                    background: currentColor;

                    transform-origin: bottom center;
                }

                &:before {
                    transform: rotateZ(-45deg);
                }

                &:after {
                    transform: rotateZ(45deg);
                }

                &:hover {
                    background-color: $primary;

                    &:not(.slick-disabled) {
                        color: #fff;
                    }

                    &.slick-disabled {
                        background-color: #eee;
                    }
                }

                button {
                    display: none;
                }
            }

            &-prev {
                transform: scaleY(-1);
            }
        }
    }
}

.good-page-tabs {

    .nav {
        padding: 0 115px;

        border: 1px solid $border-color;
        border-left: 0;
        border-right: 0;

        @include media-breakpoint-down(xl) {
            padding: 0 60px;
        }

        @include media-breakpoint-down(sm) {
            padding: 0 30px;
        }

        @include media-breakpoint-down(xs) {
            padding: 0;
        }
    }

    .nav-item {
        margin-right: 70px;

        &:last-child {
            margin-right: 0;
        }

        @include media-breakpoint-down(md) {
            margin-right: 45px;
        }

        @include media-breakpoint-down(sm) {
            margin-right: 30px;
        }

        @include media-breakpoint-down(xs) {
            margin-right: 0;
            flex-basis: 100%;
            text-align: center;
        }
    }

    .nav-link {
        position: relative;
        padding: 23px 0;

        font-size: 13px;
        font-weight: 500;
        color: $grey-dark;
        text-transform: uppercase;

        border: 0 !important;

        @include media-breakpoint-down(sm) {
            padding-top: 12px;
            padding-bottom: 12px;
        }

        &:after {
            content: "";

            position: absolute;
            bottom: 0;
            left: 0;

            width: 100%;
            height: 2px;

            background: $primary;
            opacity: 0;
            box-shadow: 0 2px 8px 0 rgba($primary, 0.7);

            transition: opacity 150ms ease;
        }

        &.active {
            color: $primary;

            &:after {
                opacity: 1;
            }
        }
    }
}
