// -------------------------------------
//   blog-page
// -------------------------------------
.blog-page {

    &-content {
        padding: 55px 0;

        &__img {
            max-width: 100%;
            margin: 40px 0 60px;
        }

        &__announce {
            max-width: 1070px;
            margin: 0 auto 40px;
            padding: 0 30px;

            font-size: 18px;
            line-height: 1.9;
            font-weight: 500;
            color: $dark;

        }

        &__main {
            padding: 10px 0;
        }

        &__text {
            margin: 0 0 60px 0;

            font-size: 14px;
            line-height: 2.2;

            ul > li:before {
                top: .8em;
            }
        }
    }

    &__container {
        max-width: 810px;
        margin: 0 auto;
        padding-left: 30px;
        padding-right: 30px;

        @include media-breakpoint-down(sm) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    &-tags {

        &__title {
            display: inline-block;
            margin: 0 45px 6px 0;

            font-size: 12px;
            font-weight: 500;
            color: $dark;
            text-transform: uppercase;

            vertical-align: middle;

            @include media-breakpoint-down(sm) {
                margin-right: 15px;
            }
        }

        &__item {
            display: inline-block;
            min-width: 75px;
            margin: 0 6px 6px 0;
            padding: 5px 12px;

            font-weight: 500;
            text-align: center;

            border-radius: 3px;
            vertical-align: middle;
        }
    }

    &-stretching {
        border: 1px solid $border-color;
        border-left: 0;
        border-right: 0;
        padding: 30px 0;
    }

    &-author {
        display: flex;
        align-items: center;

        &__img {
            width: 50px;
            height: 50px;
            margin-right: 30px;

            border-radius: 50%;
            background: center / cover no-repeat;
        }

        &__info {
            flex: 1;
        }

        &__title {
            margin-bottom: 2px;

            color: #bbb;
            font-size: 12px;
        }

        &__name {
            color: $dark;
            font-size: 14px;
            font-weight: 500;
        }
    }

    &__section-comments {
        padding: 60px 0 120px;

        background: $section-grey;
    }
}

.blog-intro {
    position: relative;
    z-index: 1;

    padding: 70px 0 30px;
    min-height: 200px;

    color: $dark;

    background: center / cover no-repeat;

    &__content {

        width: 100%;

        @include media-breakpoint-down(xs) {
            bottom: 10%;
        }
    }

    &__container {
        position: relative;

        max-width: 1070px;
        padding: 0 180px 0 30px;
        margin: 0 auto;

        @include media-breakpoint-down(xs) {
            padding-right: 30px;
        }
    }

    &__title {
        margin-top: 40px;

        font-size: 60px;
        line-height: 1;
        font-weight: 400;
        color: $dark;

        @include media-breakpoint-down(lg) {
            margin-top: 30px;
            font-size: 40px;
        }

        @include media-breakpoint-down(sm) {
            font-size: 32px;
        }
    }

    &__meta {
        position: absolute;
        right: 30px;
        bottom: 0;

        @include media-breakpoint-down(xs) {
            margin-top: 20px;
            position: static;
        }
    }

    &-info {
        display: block;

        font-size: 12px;
        text-align: right;

        &:not(:last-child) {
            margin-bottom: 7px;
        }

        &__icon {
            display: inline-block;

            width: 12px;
            height: 12px;
            margin-right: 5px;

            vertical-align: middle;
        }

        &__name {
            vertical-align: middle;
        }
    }
}

