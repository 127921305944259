@mixin scrollbar-cute {
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 8px
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background: #999;
        border: 2px solid #fff
    }

    &::-webkit-scrollbar-thumb:window-inactive {
        background: #999;
        border: 2px solid #fff
    }
}