.counter {
    display: inline-flex;
    vertical-align: middle;
    white-space: nowrap;
    font-size: 0;
    background: transparent;

    border-radius: 100px;
    border: 1px solid $border-color;

    overflow: hidden;

    &__btn {
        display: inline-block;
        line-height: 36px;
        height: 37px;
        width: 24px;
        cursor: pointer;
        border: 0;
        font-size: 0;
        background: transparent;

        opacity: .3;

        &_minus {
            background: url('../img/sprites/sprite.svg#minus') center / 11px 11px no-repeat;
        }

        &_plus {
            background: url('../img/sprites/sprite.svg#plus') center / 11px 11px no-repeat;
        }

        &:hover {
            opacity: 0.5;
        }

        &:active {
            background-color: rgba(#000, .05);
        }
    }

    &__input {
        display: inline-block;
        width: 34px;
        height: 37px;

        font-size: 13px;
        line-height: 13px;
        font-weight: 700;
        color: #666;
        text-align: center;

        background: transparent;
        border: 0;
        border-radius: 0;
        -moz-appearance: textfield; /* Firefox */

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &:focus {
            outline: none;
        }
    }

    &-lg {

        .counter__input {
            height: 48px;
            width: 40px;
            font-size: 14px;
        }

        .counter__btn {
            width: 30px;
            height: 48px;
        }

    }
}