// -------------------------------------
//   order
// -------------------------------------

.order-table {
    width: 100%;

    @include media-breakpoint-down(md) {
        display: block;

        tbody,
        thead {
            display: block;
            width: 100%;
        }

    }

    &-row {
        position: relative;

        @include media-breakpoint-down(md) {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            padding: 20px 20px 20px 110px;

            &:not(:last-child) {
                border-bottom: 1px solid #f2f2f2;
            }
        }

        @include media-breakpoint-down(xs) {
            padding-left: 20px;
        }

        &-item {
            display: flex;
            align-items: center;

            @include media-breakpoint-down(sm) {
                display: block;
            }

            &__image-place {
                flex: 0 0 70px;
                max-width: 70px;
                width: 70px;
                height: 70px;
                line-height: 70px;

                @include media-breakpoint-down(md) {
                    position: absolute;
                    left: 20px;
                    top: 25px;
                }

                @include media-breakpoint-down(xs) {
                    position: relative;
                    top: 0 !important;
                    left: 0;
                    flex: none;
                    max-width: 100%;
                    margin-bottom: 15px;
                }
            }

            &__img {
                display: inline-block;
                max-width: 100%;
                max-height: 100%;
                vertical-align: middle;
            }

            &__info {
                flex: 1 1 1px;
                padding-left: 20px;

                @include media-breakpoint-down(md) {
                    padding-left: 0;
                }

                @include media-breakpoint-down(xs) {
                    flex: none;
                    width: 100%;
                }
            }

            &__title {
                margin-bottom: 6px;

                a {
                    color: $dark !important;
                }
            }
        }


        &-value {

            &__title {
                display: none;
                font-size: 12px;
                color: #999;

                @include media-breakpoint-down(md) {
                    display: block;
                }
            }

            &__text {

            }
        }

        &__price {
            font-weight: 700;
            font-size: 15px;
            line-height: 1.05;
            white-space: nowrap;
        }

        &__price-old {
            font-size: 13px;
            color: #777;
            text-decoration: line-through;
            margin-left: 4px;
            white-space: nowrap;
        }

        &_head {
            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        &:last-child {

            .order-table__column:not(.order-table__column_head) {
                border-bottom: 0;
            }
        }
    }

    &__column {
        padding: 39px 10px 25px;
        border-top: none;
        border-bottom: 1px solid #f2f2f2;
        vertical-align: middle;

        @include media-breakpoint-down(md) {
            display: block;
            border-bottom: 0;
            padding: 0 !important;
        }

        &_head {
            padding: 7px 10px 8px;
            font-size: 13px;
            color: #999;
        }

        &_item {
            min-width: 300px;

            @include media-breakpoint-down(md) {
                flex: 0 0 100%;
                max-width: 100%;
                min-width: 1px;
            }
        }

        &_value {
            text-align: right;

            @include media-breakpoint-down(md) {
                margin-top: 10px;
                flex: 0 0 25%;
                max-width: 25%;
                text-align: left;
            }

            @include media-breakpoint-down(sm) {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }

        &:first-child {
            padding-left: 30px;
        }

        &:last-child {
            padding-right: 24px;
        }
    }

    &_slim {
        .order-table__column {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        @include media-breakpoint-down(md) {
            .order-table-row {
                padding-top: 10px;
                padding-bottom: 10px;
            }

            .order-table-row-item__image-place {
                top: 12px;
            }
        }
    }
}

.order-form {

    &-list {

        &-item {
            margin-top: 5px;

            &__title {
                display: inline;
                margin: 0 3px 0 0;
                color: #777;
                word-wrap: break-word;
            }

            &__value {
                padding: 5px 7px 5px 9px;
                background: #f0f0f0;
                border: none;
                color: #333;
                border-radius: 10px;
                display: inline-block;
                line-height: 10px;
                vertical-align: top;
                margin: 0 0 0 5px;
            }
        }
    }

    &__description {
        max-width: 400px;
        margin: 8px 0 9px 0;
        color: #777;
        font-size: 13px;
    }
}

.order-form-pickup {
    border-top: 1px solid lighten($primary, 30);
    font-size: 13px;
    word-break: break-word;

    &:last-child {
        border-bottom: 1px solid lighten($primary, 30);
    }


    background: #fff;


    &-list {

    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 35px;

        @include media-breakpoint-down(xs) {
            display: block;
        }
    }

    &__title {
        padding: 16px 10px 16px 0;
        font-size: 13px;
        font-weight: 700;
        color: $primary;
    }

    &__body {
        display: none;
        padding: 5px 35px 16px 35px;
        min-height: 80px;
    }

    &__city {
        font-weight: 700;
    }

    &__description {

    }

    &.active {
        background: lighten($primary, 47);

        .order-form-pickup__choose {
            display: none;
        }

        .order-form-pickup__body {
            display: block;
        }
    }
}

.order-total {
    border: 1px solid #f2f2f2;
    background: #fff;
    padding-bottom: 40px;

    @include media-breakpoint-down(md) {
        margin-bottom: 20px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #eaebec;
        padding: 30px;
        margin-bottom: 40px;
    }

    &__title {
        font-size: 16px;
        color: $dark;
        font-weight: 700;
    }

    &__body {
        padding-left: 30px;
        padding-right: 30px;
    }

    &-list {

        &-item {
            display: flex;
            justify-content: space-between;
            margin: 2px 0 0;
            font-size: 14px;

            &_highlight {
                background: #ffd02e;
                padding: 3px 8px 2px;
                border-radius: 3px;
                margin: 10px 0 10px;
            }
        }
    }

    &-info {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        padding: 25px 0;
        border-top: 1px solid #eaebec;
        font-weight: 700;

        &__name {
            font-size: 16px;
        }

        &__value {
            font-size: 18px;
        }
    }

    &__btn {

        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}


.order-grid {
    display: block;

    &__left {
        max-width: none;
        width: 100%;
        float: left;
        padding-right: 300px;
    }

    &__right {
        float: right;
        width: 300px;
        margin-left: -300px;
        padding-top: 24px;
        max-width: none;
    }
}


.order-complete-container {
    margin: 0 auto;
    max-width: 730px;
}

.order-payment {
    border: 1px solid #f2f2f2;
    padding: 34px 39px 34px;

    @include media-breakpoint-down(xs) {
        padding-left: 20px;
        padding-right: 20px;
    }

    &__header {
        margin: 0 0 22px;
    }

    &__title {
        font-size: 18px;
        font-weight: 700;
        line-height: 1.1;
        color: $dark;
    }

    &__view {
        display: flex;
        align-items: center;

        @include media-breakpoint-down(xs) {
            flex-wrap: wrap;
        }
    }

    &__image-place {
        display: inline-block;
        margin: 0 25px 0 0;
        width: 120px;
        height: 72px;
        line-height: 68px;
        text-align: center;
        border: 1px solid #f2f2f2;
        vertical-align: middle;
    }

    &__img {
        display: inline-block;
        max-width: 100%;
        max-height: 100%;
        vertical-align: middle;
    }

    &__name {
        margin: 10px 0;
        flex: 1 1 1px;
    }

    &__body {
        margin-top: 20px;
        padding: 24px 38px 24px 38px;
        border: 1px solid #f2f2f2;

        @include media-breakpoint-down(xs) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    &__title {

    }
}