// -------------------------------------
//   comments
// -------------------------------------
.comments {

    &-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 20px;

        border-bottom: 1px solid $border-color;

        &__title {
            font-size: 20px;
            font-weight: 700;
            color: $dark;
        }

        &__buttons {

        }

        &-button {
            display: inline-block;
            height: 50px;
            width: 50px;

            line-height: 48px;
            color: $dark;
            text-align: center;

            border: 1px solid #ddd;
            border-radius: 50%;

            transition: color 150ms ease, border-color 150ms ease, background-color 150ms ease;

            &:hover {
                color: #fff;
                border-color: $primary;
                background: $primary;
            }

            &:active {
                background: darken($primary, 10%);
            }

            &__icon {
                display: inline-block;
                width: 20px;
                height: 20px;
            }
        }
    }

    &-item {


        &__row {
            display: flex;
            padding: 30px 0;

            background-image: linear-gradient(to right, #c6c6c6 25%, rgba(#000, 0) 0%);
            background-position: bottom;
            background-size: 4px 1px;
            background-repeat: repeat-x;
        }

        &__img {
            flex: 0 0 50px;
            width: 50px;
            height: 50px;

            background: #cbcbcb center / cover no-repeat;
            border-radius: 50%;
        }

        &__body {
            flex: 1;
            padding: 0 0 0 25px;
        }

        &__head {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;

            @include media-breakpoint-down(sm) {
                display: block;
            }
        }

        &__author {
            font-size: 14px;
            font-weight: 700;
            color: $dark;
        }

        &__date {
            flex-shrink: 0;

            font-size: 12px;
            color: #bbb;
        }

        &__text {
            font-size: 14px;
            color: $grey-dark;
        }

        &__footer {
            margin-top: 20px;
        }

        &__children {
            padding-left: 75px;

            @include media-breakpoint-down(sm) {
                padding-left: 25px;
            }
        }

        &-rating {
            display: inline-block;
            margin-right: 20px;

            font-size: 14px;

            &__icon {
                color: #b5b5b5;
                vertical-align: middle;
                margin-right: 10px;
            }

            &__value {
                vertical-align: middle;
            }

            &_like {
                color: $success !important;

                &:hover, &.is-active {
                    .comments-item-rating__icon {
                        color: $success;
                    }
                }
            }

            &_dislike {
                color: $danger !important;

                &:hover, &.is-active {
                    .comments-item-rating__icon {
                        color: $danger;
                    }
                }
            }


        }

        &__answer {
            float: right;
            color: #b5b5b5;

            &:hover {
                color: $primary;
            }
        }
    }

    &-form {
        padding: 70px 0 0;

        &__title {
            margin-bottom: 30px;

            font-size: 20px;
            color: $dark;
        }
    }
}

.comments-pagination {
    margin: 0 -15px;

    &__link {
        margin: 0 15px;

        font-size: 14px;
        font-weight: 700;

        color: $dark;

        &.is-active {
            color: $secondary;
        }
    }
}
