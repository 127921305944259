// -------------------------------------
//   blog-card
// -------------------------------------
.blog-cards {

    &__row {
        margin: 0;
    }

    &__col {
        padding: 0;
        margin: 0 0 -1px 0;
    }
}

.blog-card {
    height: 100%;
    padding: 15px;
    margin-right: -1px;

    text-align: center;

    border: 1px solid $border-color;

    &__view {

        .blog-card__category {
            position: relative;
            top: -13px;
        }
    }

    &__img {
        display: block;
        padding-bottom: percentage(300 / 470);

        background: center / cover no-repeat;
    }

    &__body {
        padding: 15px 45px;
    }

    &__title {
        margin-bottom: 20px;

        font-size: 20px;
        font-weight: 500;
        color: $dark;

        a {
            color: inherit;

            &:hover {
                color: $primary;
            }
        }
    }

    &-info {
        display: inline-block;
        margin: 0 8px 5px;

        font-size: 12px;
        text-align: right;

        &:not(:last-child) {
            margin-bottom: 7px;
        }

        &__icon {
            display: inline-block;

            width: 12px;
            height: 12px;
            margin-right: 5px;

            vertical-align: middle;
        }

        &__name {
            vertical-align: middle;
        }
    }

    &__category {
        display: inline-block;
        min-width: 98px;
        padding: 5px 18px;

        font-size: 10px;
        color: #fff;
        text-transform: uppercase;
        text-align: center;

        background: $primary;
        border-radius: 2px;

        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.3);

        transition: all 100ms ease;

        &:hover {
            color: #fff;
            background: darken($primary, 7.5%);
        }

        &:active {
            color: #fff;
            background: darken($primary, 10%);
        }
    }
}