// -------------------------------------
//   simple-text
// -------------------------------------
.simple-text {
    figure {
        margin: 40px 0;

        box-shadow: 0 5px 50px 0 rgba(0, 0, 0, 0.15);

        img {

        }
    }

    figcaption {
        padding: 20px 30px;

        font-size: 12px;
    }

    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
        color: $dark;
        margin-bottom: 30px;

        &:first-child {
            margin-top: 0;
        }
    }

    h1, .h1 {
        margin-top: 80px;
    }

    h2, .h2 {
        margin-top: 70px;
    }

    h3, .h3 {
        margin-top: 60px;
    }

    h4, .h4 {
        margin-top: 50px;
    }

    .blockquote {
        position: relative;

        margin: 70px 0 30px;
        padding: 60px 70px 40px;

        font-size: 18px;
        line-height: 2;
        font-weight: 500;
        color: $dark;
        text-align: center;

        border: 1px solid $border-color;

        @include media-breakpoint-down(sm) {
            padding: 35px 15px 25px;
            font-size: 16px;
        }

        &:before {
            content: "";

            position: absolute;
            top: 0;
            left: 50%;

            width: 50px;
            height: 50px;
            margin: -25px 0 0 -25px;

            background: $primary url('../img/sprites/sprite.svg#quotes_white') center / 13px 11px no-repeat;
            border-radius: 50%;

            box-shadow: 0 5px 20px 0 rgba($primary, 0.4);
        }
    }

    .blockquote-footer {
        margin-top: 30px;

        font-size: 12px;
        color: $grey-dark;

        text-transform: uppercase;

        &:before {
            display: none;
        }
    }

    ul {
        list-style: none;
        padding: 0 0 0 15px;
        margin-bottom: 27px;

        ul, ol {
            padding-left: 0;
        }

        & > li {
            margin: 0 0 4px;
            padding-left: 20px;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0.6em;

                display: inline-block;
                width: 7px;
                height: 7px;

                border-radius: 50%;
                background: $primary;
            }
        }
    }

    ol {
        list-style: none;
        padding: 0 0 0 15px;
        counter-reset: li;
        margin-bottom: 27px;

        ul, ol {
            padding-left: 0;
        }

        & > li {
            margin: 0 0 4px;
            padding-left: 20px;
            position: relative;
            counter-increment: li;

            &:before {
                content: counter(li) ". ";
                position: absolute;
                width: 20px;
                top: 0;
                left: 0;
                border-radius: 50%;
            }
        }
    }

    ol ol, ol ul, ul ol, ul ul {
        margin-bottom: 0;
    }

}
