// -------------------------------------
//   square-card
// -------------------------------------

.square-card {
    display: flex;

    &:before {
        content: "";

        width: 1px;
        padding-bottom: 100%;
    }

    &__inner {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    &__icon-place {
        height: 100px;
        width: 100px;

        line-height: 100px;
    }

    &__icon {
        max-width: 100%;
        max-height: 100%;

        font-size: 100px;
    }
}