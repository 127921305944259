// -------------------------------------
//   footer
// -------------------------------------
.footer {
    background-color: #626366;
    color: #fff;
    font-size: .9em;
    padding-top: 85px;
    padding-bottom: 75px;

    &__link {
        color: #fff !important;
        opacity: .5;

        &:hover {
            opacity: 1;
        }
    }
}

.footer-logo {
    display: inline-block;
    margin-bottom: 25px;

    &__img {
        max-width: 120px;
    }
}

.footer-menu {

    &__title {
        margin-bottom: 20px;

        font-size: 18px;
        font-weight: 500;
        color: #fff;
    }

    &-list {
        padding: 0;
        list-style: none;

        &__item {
            display: block;
        }

        &__link {
            display: inline-block;
            padding: 8px 0;

            color: #fff !important;
            opacity: .5;

            &:hover {
                opacity: 1;
            }
        }
    }
}

.footer-socials {
    margin: 0 -10px 70px -10px;

    &__item {
        display: inline-block;
        margin: 0 10px 5px;

        color: #fff !important;
        font-size: 15px;

        &:hover {
            opacity: .8;
        }
    }
}

.footer-payments-item {
    display: inline-block;

    line-height: 1;
    vertical-align: middle;

    color: #fff;

    &__icon {
        display: inline-block;
        vertical-align: bottom;
    }

    &_paypal {
        position: relative;
        top: 2px;

        .footer-payments-item__icon {
            width: 41px;
            height: 11px;
        }
    }

    &_visa {

        .footer-payments-item__icon {
            width: 32px;
            height: 10px;
        }
    }
}