// -------------------------------------
//   subscribe
// -------------------------------------
.subscribe {

    &-section {
        margin-top: -75px;
        padding: 140px 0 110px;

        text-align: center;

        background: url(../img/main/subscribe-bg.png) center center;
    }

    &-form {

        &__title {
            margin: 10px 0;

            font-size: 20px;
            color: $dark;
            text-transform: uppercase;
        }

        &__text {
            margin: 20px 0 10px;
        }

        &-field {
            position: relative;

            margin: 50px 10px 15px;

            &__input {
                height: 55px;
                padding: 6px 35px;

                font-size: 13px;

                border: 1px solid transparent;
                border-radius: 25px;
                box-shadow: 0 0 56px 0 rgba(0, 0, 0, 0.15) !important;

                &:focus {
                    border-color: lighten($primary, 20%);
                }
            }

            &__button {
                position: absolute;
                top: 50%;
                right: 15px;

                padding: 10px;

                line-height: 1;
                color: $primary;

                background: transparent;
                border: 0;
                border-radius: 50%;

                transform: translateY(-50%);
            }
        }
    }
}
