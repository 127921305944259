html {
    font-size: 16px;
}

body {
    color: #888;
    font-size: 14px;
}

a {
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

a, button {

    &:focus {
        outline: none;
    }
}

.site-wrapper {
    overflow-y: auto;
    overflow-x: hidden;
}

.container {

    @media (min-width: 1600px) {
        max-width: 1540px;
    }
}

.bg-section-grey {
    background: $section-grey;
}

.btn {
    font-weight: 500;
}

.btn-primary {
    box-shadow: 0 5px 20px 0 rgba($primary, 0.4);
}

.btn-unrounded {
    border-radius: 3px;
}

.btn-rounded {
    border-radius: 300px;
}

.btn-icon {
    padding-left: .75rem;
    padding-right: .75rem;
    min-width: 3.125rem;
}

.btn-group-sm > .btn-icon,
.btn-icon.btn-sm {
    padding-left: .5rem;
    padding-right: .5rem;
    min-width: 2.138rem;
}

.btn-group-lg > .btn-icon,
.btn-icon.btn-lg {
    padding-left: .875rem;
    padding-right: .875rem;
    min-width: 3.75rem;
}

.unrounded {
    border-radius: 0;
}

.svg-inline {
    display: inline-block;
    width: 1em;
    height: 1em;
    vertical-align: middle;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    color: $dark;
}

h1, .h1 {

    @include media-breakpoint-down(md) {
        font-size: 2rem;
    }

    @include media-breakpoint-down(sm) {
        font-size: 1.8rem;
    }

    @include media-breakpoint-down(xs) {
        font-size: 1.6rem;
    }
}

h2, .h2 {

    @include media-breakpoint-down(md) {
        font-size: 1.8rem;
    }

    @include media-breakpoint-down(sm) {
        font-size: 1.7rem;
    }

    @include media-breakpoint-down(xs) {
        font-size: 1.5rem;
    }
}

h3, .h3 {

    @include media-breakpoint-down(md) {
        font-size: 1.6rem;
    }

    @include media-breakpoint-down(xs) {
        font-size: 1.45rem;
    }
}

h4, .h4 {
    @include media-breakpoint-down(xs) {
        font-size: 1.4rem;
    }
}

h5, .h5 {

}

h6, .h6 {

    @include media-breakpoint-down(xs) {
        font-size: .9rem;
    }
}

.cross {
    position: relative;

    display: inline-block;
    width: 1em;
    height: 1em;

    font-size: 1em;

    line-height: 1;
    text-align: center;

    vertical-align: middle;

    &:before,
    &:after {
        content: "";

        position: absolute;
        left: 50%;

        display: block;
        width: 2px;
        height: 100%;
        margin: 0 0 0 -1px;

        background: currentColor;

        transform: rotateZ(45deg);
    }

    &:after {
        transform: rotateZ(-45deg);
    }
}

.custom-control {

    &-input:not([disabled]):not(.disabled) + &-label {
        cursor: pointer;
    }
}

.custom-control_no-margins {
    padding-left: 1rem;

    .custom-control-label {

        &:before,
        &:after {
            left: -1rem;
        }
    }
}

.custom-control {

    &-input {

        &:not(:disabled):hover ~ .custom-control-label::before {
            background-color: $primary;
            border-color: $primary;
        }
    }

    &-label {
        cursor: pointer;

        &:before,
        &:after {
            margin-top: -0.15em;
        }

        &:before {
            background-color: #fff;
            border-color: #ccc;
        }

        &:after {
            background-size: 63% 63%;
        }
    }

    &-lg {
        padding-left: 2.2rem;

        .custom-control-label {
            font-size: 15px;

            &::before,
            &::after {
                left: -2.2rem;
                top: .1rem;
                width: 1.5rem;
                height: 1.5rem;
            }
        }

    }

    &_strong {


        .custom-control-label {
            font-weight: 700;
            color: $dark;
        }
    }
}

.custom-control-lg-padding {
    padding-left: 2.2rem;
}


@keyframes fadeInUpSlim {
    0% {
        opacity: 0;
        transform: translate3d(0, 20px, 0)
    }
    to {
        opacity: 1;
        transform: translateZ(0)
    }
}

@keyframes fadeInUpSlimDelay {
    0%, 33% {
        opacity: 0;
        transform: translate3d(0, 20px, 0);
    }
    to {
        opacity: 1;
        transform: translateZ(0)
    }
}
