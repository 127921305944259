// -------------------------------------
//   good-list-item
// -------------------------------------
.good-list-item {
    position: relative;

    display: flex;
    align-items: center;

    padding: 10px 35px 10px 25px;

    border-bottom: 1px solid $border-color;

    &:last-child {
        border-bottom: 0;
    }
    
    @include media-breakpoint-down(xs) {
        padding-right: 15px;
    }

    &__img-place {
        width: 70px;
        height: 70px;
        flex-shrink: 0;

        line-height: 70px;
        
        @include media-breakpoint-down(xs) {
            width: 50px;
            height: 50px;

            line-height: 50px;
        }
    }

    &__img {
        display: inline-block;
        max-width: 100%;
        max-height: 100%;

        vertical-align: middle;
    }

    &__body {
        padding-left: 30px;
        
        @include media-breakpoint-down(xs) {
            padding-left: 15px;
        }
    }

    &__title {
        display: block;
        margin-bottom: 5px;

        font-size: 12px;
        font-weight: 700;
        line-height: 1.2;
        color: $dark;
    }

    &__price {
        font-size: 12px;
        font-weight: 700;
        color: $primary;
    }

    &__delete {
        position: absolute;
        top: 8px;
        right: 20px;

        font-size: 12px;
        line-height: 1;
        color: $grey;
    }
}