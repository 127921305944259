// -------------------------------------
//   page-head
// -------------------------------------
.page-head {
    position: relative;
    z-index: 1;

    min-height: 330px;
    padding: 85px 0 110px;

    text-align: center;

    // background: primary;
    background: url(./../img/content/top-bg.jpg) center / cover no-repeat;

    color: #fff;

    &:before {
        content: "";

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;

        background: rgba(#000, .55);
    }

    &__title {
        margin-bottom: 10px;

        font-size: 25px;
        font-weight: 500;
        text-align: center;
        color: #fff;
    }
}
