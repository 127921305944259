// -------------------------------------
//   good-row
// -------------------------------------
.good-row {
    position: relative;
    z-index: 1;

    display: flex;
    align-items: center;
    padding: 15px 30px;

    background: #fff;
    border-bottom: 1px solid $border-color;

    @include media-breakpoint-down(md) {
        flex-wrap: wrap;
        padding-left: 15px;
        padding-right: 15px;
    }

    &:hover {
        z-index: 2;
        box-shadow: 0 10px 30px 0 rgba(#000, 0.1);

        &:after {
            opacity: 1;
        }
    }

    &:after {
        content: "";

        position: absolute;
        bottom: -1px;
        left: 0;
        z-index: 3;

        width: 100%;
        height: 2px;

        background: #feb312;

        opacity: 0;
        transition: opacity 200ms ease;
    }

    &__col {
        padding: 0 15px;

        &_view {
            flex: 0 0 130px;
            max-width: 130px;

            @include media-breakpoint-down(md) {
                flex-basis: 100px;
                max-width: 100px;
            }
        }

        &_body {
            flex: 1 1 auto;
            padding-left: 35px;

            @include media-breakpoint-down(md) {
                flex-basis: calc(100% - 100px);
                max-width: calc(100% - 100px);
                padding-left: 15px;
            }
        }

        &_prices {
            flex: 0 0 190px;
            max-width: 190px;

            @include media-breakpoint-down(md) {
                flex-basis: 50%;
                max-width: 50%;
            }

            @include media-breakpoint-down(xs) {
                flex-basis: 100%;
                max-width: 100%;
                margin-top: 15px;
            }
        }

        &_actions {
            flex: 0 0 230px;
            max-width: 230px;

            @include media-breakpoint-down(md) {
                flex-basis: 50%;
                max-width: 50%;
            }

            @include media-breakpoint-down(xs) {
                flex-basis: 100%;
                max-width: 100%;
                margin-top: 15px;
            }
        }
    }

    &__view {
        text-align: center;
    }

    &__img-place {
        height: 100px;

        line-height: 100px;

        @include media-breakpoint-down(md) {
            height: 70px;
            line-height: 70px;
        }
    }

    &__img {
        display: inline-block;
        max-width: 100%;
        max-height: 100%;
    }

    &__title {
        display: block;

        font-size: 14px;
        font-weight: 700;
        color: $dark;

        word-break: break-word;
    }

    &__category {
        font-size: 12px;
        color: grey;
        letter-spacing: .1em;
        text-transform: uppercase;
    }

    &__prices {
        margin-right: 10px;
    }

    &__price {
        margin-right: 5px;

        font-size: 14px;
        font-weight: 700;
        color: $primary;
    }

    &__price-old {
        font-size: 12px;
        color: $grey;
        text-decoration: line-through;
    }

    &__actions {
        text-align: right;
    }

    &__btn {
        padding: .6em 1.1em;

        font-size: 14px;
        color: $dark;

        background: #fff;

        &:hover {
            background: darken(#fff, 8%);
        }

        &:active {

        }

        &.active {
            color: #fff;

            background: lighten($primary, 6.2%);
        }

        &_buy {
            margin-left: 5px;

            border: 1px solid $border-color;
        }

        &_icon {
            min-width: 2.25em;
            padding: .3em .5em;
            margin: 0 1px;

            color: $grey;
        }

        &_compare {

        }

        &_favorite {

        }
    }

}
