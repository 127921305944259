// -------------------------------------
//   rating
// -------------------------------------
.rating {
    display: inline-flex;
    margin: 0 -2px;

    font-size: 13px;

    &-item {
        width: 1em;
        height: 1em;
        margin: 0 2px;

        color: $grey;

        background: url('../img/sprites/sprite.svg#star_inactive') center / 100% 100% no-repeat;
    }

    @for $i from 1 to 6 {
        &_#{$i} {

            .rating-item:nth-child(-n + #{$i}) {
                background-image: url('../img/sprites/sprite.svg#star_active');
            }
        }
    }
}

.rating-summ {

    &__row {
        display: flex;
        align-items: center;

        margin-bottom: 25px;
    }

    &__col {
        line-height: 1;

        &_percent {
            flex: 0 0 40px;
            max-width: 40px;

            font-size: 13px;
            color: $grey;
            text-align: right;
        }

        &_count {
            flex-grow: 1;

            font-size: 13px;
            color: $grey-dark;
            text-align: right;
        }
    }
}