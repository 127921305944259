// -------------------------------------
//   page-preloading
// -------------------------------------

.page-preloading {
    display: none;
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff url('../img/main/lazy.svg') center center / 450px 300px no-repeat;

    @at-root body.is-page-loading & {
        display: block;
    }
}
