// -------------------------------------
//   Bootstrap slider
// -------------------------------------
.slider {
    .tooltip {
        display: none;
    }

    &-handle {
        display: inline-block;
        top: -2px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        background: #fff;
        border-radius: 15px;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3);
    }

    &-track {
        box-shadow: none;
        background: #e6e6e6;
    }

    &-selection {
        background: $primary;
        box-shadow: none;
    }

    &.slider-horizontal {
        display: block;
        margin-left: 10px;
        margin-right: 10px;
        width: auto;

        .slider-track {
            height: 5px;
        }

        .slider-handle {
            margin-left: -10px;
        }

        .min-slider-handle {
            margin-left: -10px;
        }

        .max-slider-handle {
            margin-right: -10px;
        }
    }
}


// -------------------------------------
//   range-slider
// -------------------------------------
.range-slider {

    &__ranges {
        position: relative;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    &__range-place {
        flex: 0 0 50%;
        max-width: 50%;

        &_left {
            padding-right: 7px;
        }

        &_right {
            padding-left: 7px;
        }
    }

    &__range {
        width: 100%;
        padding-left: 10px;

        background: #fff;
        color: #666;
    }
}