// -------------------------------------
//   colorbox
// -------------------------------------

.colorbox {
    position: relative;

    display: block;
    width: 1em;
    height: 1em;
    margin: 0;

    font-size: 30px;
    line-height: 1;

    border-radius: 50%;
    box-shadow: 0 1px 1px rgba(#000, .3);

    overflow: hidden;
    cursor: pointer;

    &:after {
        content: "";

        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background: rgba(#000, 0);
        border-radius: 50%;
        box-shadow: 0 1px .1em 1px rgba(#000, .1) inset;

        transition: background-color 150ms;
    }

    &__input {
        display: none;
    }

    &__input:not(:disabled) + &:hover,
    &:not(.is-disabled):hover {

        &:after {
            background: rgba(#000, .05);
        }
    }

    &__input:disabled + &,
    &.is-disabled {
        opacity: .8;
        cursor: not-allowed;

        &:after {
            background: rgba(#000, .15) linear-gradient(45deg, transparent 46%, #fff 46%, #fff 54%, transparent 54%) center / 100% !important;
        }
    }

    &__input:checked + &,
    &.is-active {

        &:after {
            background: rgba(#000, .15) url('../img/sprites/sprite.svg#check_white') center / .4em .4em no-repeat !important;
        }
    }

    &__item {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background: center / cover no-repeat;

        &:nth-child(2) {
            left: 50%;
            width: 50%;
        }

        &:nth-child(3) {
            left: 50%;
            top: 50%;
            width: 50%;
            height: 50%;
        }

        &:nth-child(4) {
            left: 0%;
            top: 50%;
            width: 50%;
            height: 50%;
        }
    }
}
