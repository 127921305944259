// -------------------------------------
//   site-pagination
// -------------------------------------
.site-pagination {
    display: flex;
    justify-content: space-between;

    background: #fff;
    border: 1px solid $border-color;
    border-right: 0;
    border-left: 0;

    &__wrapper {
        margin: -1px 0 0 0;
    }

    &__items {
        display: flex;
    }


    &__link {
        position: relative;

        display: block;
        width: 75px;
        height: 75px;
        margin: -1px -1px -1px 0;

        font-weight: 500;
        line-height: 75px;
        color: $dark;
        text-align: center;

        border: 1px solid $border-color;

        @include media-breakpoint-down(sm) {
            width: 45px;
            height: 45px;

            line-height: 45px;
        }

        &_arrow {
            color: $grey;
        }

        @at-root a#{&} {
            &:hover {
                color: $dark;
                background: rgba($primary, .3);
            }

            &:active {
                background: rgba($primary, .5);
            }
        }

        &.is-active {
            z-index: 1;

            color: #fff;

            background: $primary;
            border-color: $primary;
            box-shadow: 0 5px 20px 0 rgba($primary, 0.55);
        }
    }
}