// -------------------------------------
//   cart-table
// -------------------------------------
.cart-table {

}

.cart-table-item {
    position: relative;
    z-index: 1;

    background: #fff;
    border-bottom: 1px solid $border-color;

    &__row {
        display: flex;
        align-items: center;
        max-width: 1060px;
        margin: 0 auto;
        padding: 15px;
        
        @include media-breakpoint-down(md) {
            flex-wrap: wrap;
        }
    }

    &__col {
        padding: 0 15px;

        &_view {
            flex: 0 0 130px;
            max-width: 130px;

            @include media-breakpoint-down(md) {
                flex-basis: 100px;
                max-width: 100px;
                margin-bottom: 10px;
            }
        }

        &_body {
            flex: 1 1 auto;
            padding-left: 30px;

            @include media-breakpoint-down(md) {
                flex-basis: calc(100% - 100px);
                max-width: calc(100% - 100px);
                padding-left: 15px;
                padding-right: 35px;
                margin-bottom: 10px;
            }
        }

        &_price {
            flex: 0 0 170px;
            max-width: 170px;

            @include media-breakpoint-down(md) {
                flex-basis: 33%;
                max-width: 33%;

                text-align: center;
            }

            @include media-breakpoint-down(xs) {
                flex-basis: 50%;
                max-width: 50%;

                text-align: left;
            }
        }

        &_total {
            flex: 0 0 170px;
            max-width: 170px;

            @include media-breakpoint-down(md) {
                flex-basis: 33%;
                max-width: 33%;

                text-align: center;
            }

            @include media-breakpoint-down(xs) {
                flex-basis: 100%;
                max-width: 100%;
                margin-top: 10px;

                text-align: right;
            }
        }

        &_count {
            flex: 0 0 132px;
            max-width: 132px;

            @include media-breakpoint-down(md) {
                flex-basis: 33%;
                max-width: 33%;

                text-align: center;
            }

            @include media-breakpoint-down(xs) {
                flex-basis: 50%;
                max-width: 50%;

                text-align: right;
            }
        }

        &_actions {
            flex: 0 0 80px;
            max-width: 80px;

            @include media-breakpoint-down(md) {
                flex-basis: auto;
                padding: 0;
            }
        }
    }

    &__view {
        height: 100px;

        line-height: 100px;
        text-align: center;

        @include media-breakpoint-down(md) {
            height: 70px;
            line-height: 70px;
        }
    }

    &__img {
        display: inline-block;
        max-width: 100%;
        max-height: 100%;
    }

    &__title {
        display: block;

        font-size: 14px;
        font-weight: 700;
        color: $dark;

        word-break: break-word;
    }

    &__category {
        font-size: 12px;
        color: grey;
        letter-spacing: .1em;
        text-transform: uppercase;
    }

    &__price {
        font-size: 14px;
        font-weight: 700;
        color: $grey;
    }

    &__total {
        font-size: 14px;
        font-weight: 700;
        color: $primary;
    }

    &__btn {

        &_remove {

            @include media-breakpoint-down(md) {
                position: absolute;
                right: 10px;
                top: 10px;

                min-width: 1.9rem;
                padding: .45rem .5rem;

                font-size: .8rem;
                line-height: 1;
            }
        }
    }
}

.cart-table-total {
    background: #fff;
    border-bottom: 1px solid $border-color;

    &__row {
        display: flex;
        align-items: center;
        max-width: 1060px;
        margin: 0 auto;
        padding: 15px;
        
        @include media-breakpoint-down(sm) {
            flex-wrap: wrap;
        }
    }

    &__col {
        padding: 0 15px;


        &_body {
            flex: 1 1 auto;
            
            @include media-breakpoint-down(sm) {
                flex-basis: 100%;
                max-width: 100%;

                margin-bottom: 15px;
            }
        }

        &_price {
            flex: 0 0 170px;
            max-width: 170px;

            @include media-breakpoint-down(md) {
                flex: 0 1 auto;
                max-width: 100%;

                white-space: nowrap
            }
        }

        &_label {
            flex: 0 1 auto;

            text-align: right;
            
            @include media-breakpoint-down(sm) {
                flex: 1 1 auto;
            }
        }

        &_actions {
            flex: 0 0 80px;
            max-width: 80px;
        }
    }

    &__label {
        font-size: 14px;
        font-weight: 700;
        color: $dark;

        white-space: nowrap;
    }
}