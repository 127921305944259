// -------------------------------------
//   banner
// -------------------------------------

.banner {
    position: relative;
    z-index: 1;

    display: flex;
    flex-direction: column;
    min-height: 350px;
    height: 100%;
    padding: 40px 30px 40px 50px;

    color: $dark !important;

    background: center / cover no-repeat;
    
    @include media-breakpoint-down(md) {
        min-height: 250px;
    }

    &:hover {
        z-index: 2;

        box-shadow: 0 0 30px rgba(#000, .15);

        .banner__btn {
            border-color: #000;
        }
    }

    &.light {
        color: #fff !important;

        &:after {
            background: linear-gradient(37deg, rgba(#000, .7) 200px, transparent 500px);
        }

        .banner__btn {

            background: rgba(#fff, .3);
        }

        &:hover {

            .banner__btn {
                border-color: #fff;
            }
        }
    }

    &:after {
        content: "";

        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;

        height: 100%;
        width: 100%;

        background: linear-gradient(37deg, rgba(#fff, .7) 200px, transparent 500px);
    }

    &__content {
        max-width: 275px;
        margin-top: auto;
    }

    &__title {
        font-size: 22px;
        margin-bottom: 5px;
        
        @include media-breakpoint-down(md) {
            font-size: 20px;
        }
    }

    &__text {
        font-size: 16px;
        
        @include media-breakpoint-down(md) {
            font-size: 14px;
        }
    }

    &__btn {
        margin-top: 20px;
        padding: 10px 20px;
        min-width: 160px;

        color: #fff;

        background: rgba(#000, .35);
        border: 1px solid transparent;
        box-shadow: 0 0 10px 0 rgba(#000, .1);

        &:hover {
            color: #fff;
        }
    }
}