// -------------------------------------
//   catalog
// -------------------------------------
.catalog-grid {

    &__row {

    }

    &__col {

        &_side {
            position: relative;
            z-index: 5;

            border-right: 1px solid $border-color;
            margin-right: -1px;
        }

        &_main {

        }
    }
}

.catalog-menu {
    height: 100%;
    padding: 60px 15px;

    text-align: center;

    border: 1px solid $border-color;
    border-top: 0;
    border-left: 0;

    &__title {
        display: block;
        margin-bottom: 20px;

        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.01em;
        color: $dark;
        text-transform: uppercase;
    }

    &__img-place {
        width: 75px;
        height: 75px;
        margin: 0 auto 20px;

        line-height: 75px;
        color: $primary;
    }

    &__img {
        max-width: 100%;
        max-height: 100%;

        @at-root svg#{&} {
            font-size: 75px;
        }
    }

    &-list {
        padding: 0;
        margin: 0;

        font-size: 12px;

        list-style: none;

        &__item {
            display: block;
            padding: 5px 0;
        }

        &__link {
            color: $grey;

            &:hover {
                color: $dark;
            }
        }
    }
}

.catalog-filter {
    background: #fff;

    &-head {
        position: relative;
        display: flex;

        &__title {
            position: relative;
            display: block;
            flex: 1 1 auto;
            height: 74px;
            padding-left: 60px;
            margin: -1px 0 -1px -1px;

            font-size: 14px;
            font-weight: 700;
            line-height: 74px;

            @include media-breakpoint-down(lg) {

                &:hover {
                    background: rgba($primary, .1);
                }

                &.active {
                    background: $primary;
                    color: #fff;
                }
            }

            @include media-breakpoint-down(md) {
                height: 60px;
                line-height: 60px;
            }

            @include media-breakpoint-down(sm) {
                padding-left: 40px;
            }
        }

        &__icon {
            position: absolute;
            left: 30px;
            top: 50%;

            margin-top: -.55em;

            font-size: 1.3em;

            @include media-breakpoint-down(sm) {
                left: 15px;
            }
        }

        &__buttons {
            display: flex;
        }

        &__mobile-place {
            position: absolute;
            top: 100%;
            left: -1px;
            right: -1px;
            z-index: 5;

            display: none;

            border-top: 1px solid $primary;
            box-shadow: 0 15px 15px 0 rgba(0,0,0,.15);

            @include media-breakpoint-down(lg) {
                &.active {
                    display: block;
                }
            }
        }
    }

    &-group {
        border-bottom: 1px solid $border-color;

        &__head {
            position: relative;
            display: block;
            padding: 23px 60px;

            &.collapsed {

                .catalog-filter-group__arrow {
                    transform: translateY(-75%) rotateZ(45deg);
                }
            }
        }

        &__arrow {
            display: block;
            position: absolute;
            top: 50%;
            left: 30px;

            width: 8px;
            height: 8px;

            border: 2px solid $dark;

            border-top: 0;
            border-left: 0;

            transition: transform 150ms linear;

            transform: translateY(-25%) rotateZ(-135deg);
        }

        &__title {
            font-size: 13px;
            font-weight: 500;
            color: $dark;
            text-transform: uppercase;
        }

        &__collapse {

        }

        &__content {
            padding: 1px 60px 30px;
        }
    }

    &-flex {
        display: flex;
        align-items: center;
        padding: 5px 0;
        margin-bottom: 5px;

        font-size: 13px;

        &__label {
            display: block;
            flex-grow: 1;
            margin: 0;
            padding-right: 10px;

            font-weight: 500;
            color: $dark;

            cursor: pointer;
        }

        &__value {
            color: $grey-dark;
        }

        &.active {

            .catalog-filter-flex__label,
            .catalog-filter-flex__value {
                color: $secondary;
            }
        }
    }

    &-colors {
        font-size: 0;
        margin: 0 -7px;

        &__item {
            display: inline-block;
            margin: 7px;
        }
    }
}
