// -------------------------------------
//   intro
// -------------------------------------

.intro-item {
    position: relative;
    z-index: 1;

    display: flex;
    flex-direction: column;
    min-height: 700px;
    height: calc(100vh - 75px);

    color: $dark;

    background: center / cover no-repeat;

    @include media-breakpoint-down(md) {
        height: 600px;
        min-height: 1px;
        padding-bottom: 100px;
    }

    &.light {
        color: #fff;

        &:before {
            background: rgba(#000, .25) linear-gradient(to bottom, rgba(#000, .3), transparent 10%, transparent 85%, rgba(#000, .4));
        }
    }

    &:before {
        content: "";

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;

        background: rgba(#fff, .25) linear-gradient(to bottom, rgba(#fff, .3), transparent 10%, transparent 85%, rgba(#fff, .4));
    }

    &__container {
        margin-top: auto;
        margin-bottom: auto;

        padding-left: 100px;
        
        @include media-breakpoint-down(md) {
            padding-left: 30px;
        }
    }

    &__content {
        max-width: 70%;
        margin: auto 0;
        
        @include media-breakpoint-down(sm) {
            max-width: 100%;
        }
    }

    &__title {
        margin-bottom: 20px;

        font-size: 70px;
        font-weight: 900;
        line-height: 1.1;

        @at-root .intro .slick-current & {
            animation: fadeInUpSlim 1s ease 1;
        }

        @include media-breakpoint-down(lg) {
            font-size: 50px;
        }

        @include media-breakpoint-down(md) {
            font-size: 40px;
        }
        
        @include media-breakpoint-down(sm) {
            font-size: 35px;
        }
    }

    &__text {
        font-size: 35px;
        line-height: 1.2;

        @at-root .intro .slick-current & {
            animation: fadeInUpSlimDelay 1.5s ease 1;
        }

        @include media-breakpoint-down(lg) {
            font-size: 27px;
        }

        @include media-breakpoint-down(md) {
            font-size: 24px;
        }
        
        @include media-breakpoint-down(sm) {
            font-size: 20px;
        }
    }

    &__btn {
        margin-top: 40px;
        padding: 13px 30px;
        min-width: 200px;

        font-size: 18px;
        color: #fff;

        background: rgba(#000, .45);
        border: 1px solid transparent;
        box-shadow: 0 0 20px 0 rgba(#000, .2);

        @at-root .intro .slick-current & {
            animation: fadeInUpSlimDelay 1.5s ease 1;
        }

        @include media-breakpoint-down(sm) {
            font-size: 15px;
            min-width: 180px;
            margin-top: 30px;
        }

        &:hover {
            color: #fff;
            border-color: #fff;
        }
    }
}

.intro {
    position: relative;

    .slick-dots {
        display: inline-block;
        padding: 0;
        margin: 0;

        li {
            position: relative;

            display: inline-block;
            height: 10px;
            width: 10px;
            margin: 3px;

            border-radius: 50%;
            background: $grey;

            cursor: pointer;

            button {
                display: none;
            }

            &.slick-active {
                background-color: #fff;
            }
        }
    }

    .slick-arrow {
        position: absolute;

        width: 60px;
        height: 60px;
        padding: 0;

        font-size: 0;
        color: #fff;

        background: rgba($grey, .4);
        border: 2px solid $grey;
        border-radius: 50%;
        box-shadow: 0 0 10px rgba(#000, .3);
        opacity: 0.8;

        transition: opacity 100ms ease;

        @include media-breakpoint-down(md) {
            width: 40px;
            height: 40px;
        }

        &:hover,
        &:active {
            color: #fff;

            background-color: $grey;

            opacity: 1;
        }

        &:active {
            opacity: .8;
        }

        &:before,
        &:after {
            content: "";

            position: absolute;
            top: 50%;
            left: 50%;

            width: 10px;
            height: 2px;
            margin-left: -5px;

            background: currentColor;

            transform-origin: right center;
        }

        &:before {
            transform: rotateZ(-45deg);
        }

        &:after {
            transform: rotateZ(45deg);
        }

        &.slick-disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    .slick-prev {
        left: 0;
        transform: scaleX(-1);
        
        @include media-breakpoint-down(md) {
            left: auto;
            right: 65px;
        }
    }

    .slick-next {
        right: 0;
        
        @include media-breakpoint-down(md) {
            right: 15px;
        }
    }
}

.intro-slider-nav {

    &__arrows-container {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;

        height: 1px;
        margin-top: -30px;

        @include media-breakpoint-down(md) {
            top: auto;
            bottom: 110px;

            margin-top: 0;
            margin-bottom: 40px;
        }
    }

    &__dots-container {
        position: absolute;
        bottom: 150px;
        left: 0;
        right: 0;

        padding-right: 45px;

        text-align: right;

        @include media-breakpoint-down(md) {
            bottom: 80px;

            padding-right: 15px;
        }
    }
}