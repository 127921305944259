.mobile-menu {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 300px;
    max-width: 100%;
    z-index: 1030;
    overflow: hidden;
    overflow-y: auto;
    background: #fff;

    transform: translateX(-100%);
    transition: transform .4s ease 0s, opacity .4s ease 0s;

    &.show {
        transform: translateX(0);
    }

    &__scroller {
        overflow: hidden;
    }

    &__wrapper {
        transition: transform 200ms linear;
    }

    &-overlay {
        position: fixed;
        display: none;
        left: 0;
        top: 0;
        z-index: 1029;
        height: 100%;
        width: 100%;
        background: rgba(#3a3f44, .7);
        z-index: 99;
    }

    @include scrollbar-cute;
}


// /-- Mobile Header


// -- Mobile Side

.mobile-menu-list {
    padding: 0;
    margin: 0;
    list-style: none;

    &__item {
        display: block;
        list-style: none;
        width: 100%;
        margin: 0;
        padding: 0;
        border-bottom: 1px solid #f2f2f2;

        &_grey {
            background: #fbfbfb;
        }

        &_back {

            .mobile-menu-list__link {
                padding-left: 57px;

                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 20px;
                    width: 22px;
                    height: 22px;
                    margin-top: -12px;
                    background: url('../img/sprites/sprite.svg#arrow-left') center / 13px 13px no-repeat;
                }
            }
        }

        &_title {
            background-color: #fbfbfb;

            .mobile-menu-list__link {
                font-weight: 700 !important;
                color: $primary;
            }
        }

        &_parent {

            & > .mobile-menu-list__link {
                padding-right: 46px;

                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 50%;
                    right: 20px;
                    margin: -3px 0 0 -3px;
                    border: 3px solid transparent;
                    border-left-color: currentColor;
                }
            }
        }
    }

    &__link {
        padding: 19px 20px 20px 19px;
        display: block;
        position: relative;
        color: #222;
        font-weight: 700;
        font-size: 15px;

        &:hover {

            @at-root a#{&} {
                color: $primary;
            }
        }

        &_active {
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                margin: auto 0;
                width: 2px;
                height: 100%;
                background: $primary;
            }
        }
    }

    &__link-icon {
        position: relative;
        top: -2px;
        max-width: 17px;
        max-height: 17px;
        margin-right: 20px;
    }

    &_child {
        display: none;
        position: absolute;
        top: 0;
        left: 100%;
        width: 100%;

        .mobile-menu-list__link {
            font-weight: 400;

            &_active {
                &:before {
                    height: 4px;
                    width: 4px;
                    left: 9px;
                    border-radius: 50%;
                }
            }
        }
    }

    &_expanded {
        display: block;
    }
}

.mobile-menu-block {

    &__title {
        font-weight: 700;
        padding: 19px 20px 6px 19px;
    }

    &_socials {
        padding: 6px 0 30px;
    }
}

.mobile-contacts {
    padding: 0;
    margin: 0;
    list-style: none;
    padding-bottom: 10px;

    &__item {
        list-style: none;
    }

    &__link {
        display: block;
        padding: 12px 20px 0 50px;
        position: relative;
        line-height: 18px;
        font-size: 13px;
        color: #333;

        &:hover {
            @at-root a#{&} {
                color: $primary;
            }
        }
    }

    &__icon {
        display: inline-block;
        position: absolute;
        left: 20px;
        top: 12px;
        width: 18px;
        height: 18px;
    }

    &__socials {

        .mobile-contacts__icon {
            top: 11px;
            width: 20px;
            height: 20px;
        }
    }
}

// /-- Mobile Side

// Count Link

.count-link {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;

    &__icon {
        display: inline-block;
        width: 17px;
        height: 17px;
        margin-right: 20px;
    }

    &__title {

    }

    &__count {
        display: inline-block;
        width: 17px;
        height: 17px;
        line-height: 17px;
        border-radius: 8px;
        text-align: center;
        white-space: nowrap;
        font-size: 12px;
        color: #fff;
        background: #b0b0b0;
        font-weight: initial;
        margin-left: 7px;

        box-shadow: 0 0 0 2px #fff;
    }

    &.active {

        .count-link__count {
            background: $primary;
        }
    }
}

// /Count Link