// -------------------------------------
//   sizes-grid
// -------------------------------------

.sizes-grid {
    display: flex;
    flex-wrap: wrap;

    &__cell {
        flex: 0 0 50px;
        max-width: 50px;
        margin: 0 -1px -1px 0;
    }

    &__item {
        position: relative;

        display: block;
        width: 100%;
        height: 52px;
        margin: 0;

        font-size: 14px;
        line-height: 52px;
        font-weight: 500;
        color: $dark;
        text-align: center;

        border: 1px solid $border-color;

        cursor: pointer;
    }

    &__input {
        display: none;
    }

    &__input:not(:disabled) + &__item:hover {
        background: rgba($primary, .1);
    }

    &__input:disabled + &__item {
        opacity: .9;
        cursor: not-allowed;

        background: rgba(#666, .05) !important;
    }

    &__input:checked + &__item {
        z-index: 1;
        color: #fff;

        background: $primary !important;
        border-color: $primary;
        box-shadow: 0px 5px 20px 0px rgba($primary, 0.55);
    }
}