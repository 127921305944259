// -------------------------------------
//   rounded-form
// -------------------------------------
.rounded-form {
    border: 1px solid #ddd;
    border-radius: 10px;

    overflow: hidden;

    &__row {
        margin: -1px -3px -1px -1px;
    }

    &__col {
        margin: 0 -1px -1px 0;
    }

    &__input {
        height: 58px;
        padding: 20px;

        font-size: 13px;
        line-height: 20px;
        color: $dark;

        background: transparent !important;
        border: 1px solid #ddd !important;
        border-radius: 0;
        box-shadow: none !important;

        resize: none;

        @at-root textarea#{&} {
            height: auto;
        }

        &:focus, &.is-active {

            & + .rounded-form__label {
                opacity: .8;
                transform: translateY(-14px) scale(0.85);
            }
        }
    }

    &__btn {
        display: block;
        height: 58px;
        width: 100%;

        border-radius: 0;
    }

    &__field {
        position: relative;
    }

    &__label {
        position: absolute;
        top: 20px;
        left: 20px;

        font-size: 13px;
        color: $grey-dark;

        transform-origin: top left;
        transition: transform 200ms ease, opacity 200ms ease;
    }
}
