// -------------------------------------
//   lazy-youtube
// -------------------------------------

.lazy-youtube {
    position: absolute;
    background: #424242 center / cover no-repeat;
    cursor: pointer;

    &:before {
        content: "";
        position: absolute;
        height: 100px;
        width: 100%;
        background-repeat: repeat-x;
        background-position: top;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==);
        pointer-events: none;
    }

    &__btn {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        width: 68px;
        height: 48px;
        margin-left: -34px;
        margin-top: -24px;
        background: #212121;
        opacity: 0.8;
        border-radius: 15px;
        padding: 0;
        border: 0;

        transition: color .25s cubic-bezier(0.0, 0.0, 0.2, 1),
        opacity .25s cubic-bezier(0.0, 0.0, 0.2, 1);


        &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            display: inline-block;
            border: 11px solid transparent;
            border-left: 20px solid #fff;
            margin-top: -11px;
            margin-left: -7px;
        }
    }

    &:hover {

        .lazy-youtube__btn {
            background: #f00;
            opacity: 1;
        }
    }
}
