// -------------------------------------
//   panel
// -------------------------------------

.panel {
    background: #fff;
    border: 1px solid #f2f2f2;
    margin-bottom: 20px;

    &__header {
        position: relative;
        padding: 29px 19px 29px 79px;
        margin-bottom: -1px;
    }

    &__title {
        font-size: 20px;
        line-height: 1.2;
        font-weight: 600;
        color: $dark;

        @include media-breakpoint-down(xs) {
            font-size: 18px;
        }
    }

    &__icon {
        position: absolute;
        left: 27px;
        top: 23px;
        width: 30px;
        height: 30px;
        color: $primary;
    }

    &__content {
        padding: 0 29px 25px 79px;
        font-size: 15px;

        @include media-breakpoint-down(md) {
            padding-left: 29px;
        }
    }

    &__body {
        padding: 25px;
    }

    &_gray {
        .panel__header {
            background: #fbfbfb;
            border-bottom: 1px solid #f2f2f2;
        }
    }

}
