// -------------------------------------
//   section
// -------------------------------------

.section {

    &-head {
        position: relative;

        display: flex;
        flex-direction: column;
        min-height: 100px;
        padding: 25px 30px;

        &_with-actions {
            padding-left: 150px;
            padding-right: 150px;

            @include media-breakpoint-down(md) {
                padding-left: 30px;
                padding-right: 30px;
                padding-bottom: 75px;
            }
        }

        &__content {
            margin: auto 0;
            text-align: center;
        }

        &__actions {
            position: absolute;
            top: 50%;

            transform: translate(0, -50%);
            
            @include media-breakpoint-down(md) {
                top: auto;
                bottom: 40px;
                
                transform: translate(0, 50%);
            }

            &_left {
                left: 50px;
                
                @include media-breakpoint-down(md) {
                    left: 30px;
                }
            }

            &_right {
                right: 50px;
                
                @include media-breakpoint-down(md) {
                    right: 30px;
                }
            }
        }

        &__slick-arrows {

            .slick-arrow {
                position: relative;
                width: 50px;
                height: 50px;
                padding: 0;

                font-size: 0;
                color: $grey;

                background: transparent center / 20px 20px no-repeat;
                border: 1px solid #e5e5e5;
                border-radius: 0px 50px 50px 0px;
                opacity: 0.8;

                transition: opacity 100ms ease;

                &:hover {
                    opacity: 1;
                    background-color: #f5f5f5;
                }

                &:active {
                    background-color: #eee;
                }

                &:before,
                &:after {
                    content: "";

                    position: absolute;
                    top: 50%;
                    left: 20px;

                    width: 9px;
                    height: 2px;

                    background: currentColor;

                    transform-origin: right center;
                }

                &:before {
                    transform: rotateZ(-45deg);
                }

                &:after {
                    transform: rotateZ(45deg);
                }

                &.slick-disabled {
                    opacity: 0.5;
                    pointer-events: none;
                }
            }

            .slick-prev {
                transform: scaleX(-1);
                margin-right: -1px;
            }

            .slick-next {

            }
        }
    }

    &__content {

    }
}