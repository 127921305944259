// -------------------------------------
//   timer
// -------------------------------------

.timer {
    font-size: 15px;
    
    @include media-breakpoint-down(xs) {
        font-size: 10px;
    }

    &__line {
        display: inline-flex;

        background: #fff;
        border-radius: (10 / 15) + em;
        box-shadow: 0 (5 / 15) + em (20 / 15) + em 0px rgba(#000, 0.1);

        overflow: hidden;
    }

    &-item {
        position: relative;

        width: (75 / 15) + em;
        height: (75 / 15) + em;
        padding-top: (20 / 15) + em;

        &:not(:last-child):after {
            content: "";

            position: absolute;
            right: 0;
            top: 24%;
            bottom: 18%;

            width: 1px;

            background: $border-color;
        }

        &__value {
            font-size: (25 / 15) + em;
            line-height: 1;
            font-weight: 500;
        }

        &__name {
            font-size: (13 / 15) + em;
            line-height: 1;
            font-weight: 500;
            color: $primary;
        }
    }
}