// -------------------------------------
//   load-more
// -------------------------------------
.load-more-stretching {
    display: block;
    padding: 25px 0;

    font-size: 12px;
    font-weight: 500;
    color: $dark;
    text-align: center;
    text-transform: uppercase;

    border: 1px solid $border-color;
    border-left: 0;
    border-right: 0;

    &__icon {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 10px;

        color: $primary;

        vertical-align: middle;
    }

    &__text {
        vertical-align: middle;
    }

    &:hover {
        color: #fff;

        background: $primary;
        border-color: $primary;

        .load-more-stretching__icon {
            color: #fff;
        }
    }

    &.is-active {

        .load-more-stretching__icon {

            animation: loadMoreRotate 0.7s infinite linear;
        }
    }
}

@keyframes loadMoreRotate {
    0% {
        transform: rotateZ(360deg);
    }

    100% {
        transform: rotateZ(0)
    }
}