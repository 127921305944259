// -------------------------------------
//   card-shadow
// -------------------------------------

.card-shadow {
    position: relative;
    z-index: 1;

    margin: 0 -1px 0 0;

    box-shadow: 0 0 0 1px $border-color inset;

    &_hover {
        &:hover {
            z-index: 2;
            box-shadow: 0 0 20px 0 rgba(#000, .1);
        }
    }


    &__col-fix {
        margin-bottom: -1px;
    }
}