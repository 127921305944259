// -------------------------------------
//   good
// -------------------------------------
.good-badge {
    display: inline-block;
    padding: 5px;

    color: #fff;
    font-size: 11px;
    line-height: 1.1;

    background: $dark;
    border-radius: 3px;

    vertical-align: middle;

    &_hit {
        background-color: #2992d9;
    }

    &_sale {
        background-color: #e52929;
    }

    &_recommend {
        background-color: #893ca9;
    }

    &_archive {
        background-color: #ccc;
    }

    &_event {
        background-color: #fa9308;
    }

    &_bonus {
        background-color: #893ca9;
    }

    &_action {
        background-color: #004085;
    }
}
