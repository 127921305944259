// -------------------------------------
//   cover
// -------------------------------------
.cover-section {
    position: relative;
    z-index: 3;

    margin-top: -75px;
    margin-bottom: -75px;

    @include media-breakpoint-down(md) {
        margin-top: -60px;
    }

    &__container {
        min-height: 150px;
        padding-left: 0;
        padding-right: 0;
        background: #fff;
        box-shadow: 0 0 65px 0 rgba(0, 0, 0, 0.2);
    }
}

.cover-head {
    position: relative;
    z-index: 5;

    &__row {
        margin: 0;
        padding: 0;
    }

    &__col {
        padding: 0;
    }

    &__item {
        position: relative;

        height: 75px;
        font-size: 14px;
        font-weight: 700;
        color: $dark;

        background: #fff;
        border: 1px solid $border-color;
        border-top: 0;
        border-left: 0;

        @include media-breakpoint-down(md) {
            height: 60px;
        }

        &.active {
            z-index: 1;

            color: #fff;

            background: $primary;
            border-color: $primary;
        }
    }

    &-text {
        display: block;
        height: 74px;

        font-size: 14px;
        font-weight: 600;
        line-height: 74px;
        text-align: center;
        text-transform: uppercase;

        @at-root a#{&} {
            color: $dark;

            &:hover {
                background: rgba($primary, .1);
            }
        }

        @include media-breakpoint-down(md) {
            height: 59px;

            line-height: 59px;
        }

        &__value {
            display: inline-block;

            line-height: normal;

            vertical-align: middle;
        }
    }

    &__btn {
        position: relative;

        display: inline-block;
        height: 74px;
        width: 74px;
        margin: 0 -1px 0 -1px;

        font-size: 17px;
        line-height: 72px;
        color: $grey;
        text-align: center;

        background: #fff;
        border: 1px solid $border-color;
        border-top: 0;
        border-bottom: 0;

        box-shadow: none !important;

        &:hover,
        &.active {
            color: $primary;
        }

        @include media-breakpoint-down(md) {
            height: 59px;
            width: 59px;
            line-height: 56px;
        }
    }

    &-flex {
        display: flex;

        &__side {
            flex: 0 0 auto;

            &_flex {
                flex: 1 1 auto;
            }
        }
    }
}

.cover__nav-tabs {
    border: 0;

    .nav-link {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 75px;
        margin: 0;

        font-size: 12px;
        font-weight: 700;
        color: $grey;
        text-align: center;
        text-transform: uppercase;

        background: #f7f7f7;
        border: 0;
        border-right: 1px solid $light;
        border-bottom: 1px solid $light;
        border-radius: 0;

        @include media-breakpoint-down(md) {
            height: 60px;
        }

        &:hover {
            background: $light;
        }

        &.active {
            color: #fff;

            border-color: $primary;
            background: $primary;
        }
    }
}


.cover-footer {

    &__btn {
        display: inline-block;
        height: 75px;
        width: 100%;
        margin: -1px 0;

        font-size: 13px;
        font-weight: 700;
        line-height: 72px;
        text-transform: uppercase;
        text-align: center;

        border: 1px solid $border-color;
    }
}
