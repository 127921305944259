
.select2 {

    &-container {
        display: block;

        &--default {

            .select2-selection {
                color: inherit;

                border-color: $border-color;

                .select2-selection__arrow {
                    top: 50%;
                    margin-top: -13px;

                    b {
                        border-top-color: currentColor;
                    }
                }

                .select2-selection__rendered {
                    display: block;

                    color: inherit;
                }

                &--multiple {

                    .select2-selection__choice {
                        background-color: $light;
                        border-color: $light;
                        color: $dark;
                    }
                }
            }

            &.select2-container--open .select2-selection--single .select2-selection__arrow b {
                border-bottom-color: currentColor;
                opacity: .7;
            }

            .select2-results__option {
                padding: 6px 15px;
                color: $dark;

                &--highlighted[aria-selected] {
                    background: $primary;
                }
            }

            &.select2-container--focus  {

                .select2-selection--multiple {
                    border-color: $primary;
                }
            }
        }
    }

    &-dropdown {
        border-color: $border-color;
        box-shadow: 0px 2px 4px 0px rgba(#000, 0.075);
    }

    &-selection {

        &:focus {
            outline: none;
        }

        &__arrow {

        }

        &--single {

            .select2-selection__rendered {
                //font-weight: 600;
                color: $dark;
                //text-transform: uppercase;
            }
        }
    }
}

.form-control {

    & + .select2 {

        &-container {

            &--default {

                .select2-selection {
                    @include border-radius($input-border-radius);

                    @include font-size($input-font-size);

                    .select2-selection__rendered {
                        padding: $input-padding-y $input-padding-x;
                    }

                    &--multiple {
                        min-height: $input-height;

                        .select2-selection__rendered {
                            padding-top: $input-padding-y - rem(5px);

                            font-size: 14px;
                        }
                    }

                    &--single {
                        height: $input-height;

                        .select2-selection__rendered {
                            padding-right: 40px;

                            line-height: $input-line-height;
                        }

                        .select2-selection__arrow {
                            right: 15px;
                        }
                    }
                }
            }
        }
    }

    &-sm + .select2 {

        &-container {

            &--default {

                .select2-selection {
                    @include border-radius($input-border-radius-sm);

                    @include font-size($input-font-size-sm);

                    .select2-selection__rendered {
                        padding: $input-padding-y-sm $input-padding-x-sm;
                    }

                    &--multiple {
                        min-height: $input-height-sm;

                        .select2-selection__choice {
                            margin-top: 3px;
                        }

                        .select2-selection__rendered {
                            padding-top: $input-padding-y-sm - rem(3px);

                            font-size: 13px;
                        }
                    }

                    &--single {
                        height: $input-height-sm;

                        .select2-selection__rendered {
                            padding-right: 40px;

                            line-height: $input-line-height-sm;
                        }
                    }
                }

            }
        }
    }

    &-lg + .select2 {

        &-container {

            &--default {

                .select2-selection {
                    @include border-radius($input-border-radius-lg);

                    @include font-size($input-font-size-lg);

                    .select2-selection__rendered {
                        padding: $input-padding-y-lg $input-padding-x-lg;
                    }

                    &--multiple {
                        min-height: $input-height-lg;

                        .select2-selection__rendered {
                            padding-top: $input-padding-y-lg - rem(3px);

                            font-size: 14px;
                        }
                    }

                    &--single {
                        height: $input-height-lg;

                        .select2-selection__rendered {
                            padding-right: 40px;

                            line-height: $input-line-height-lg;
                        }
                    }
                }
            }
        }
    }
}

.cover-head__select {

    & + .select2 {
        margin: 0 -1px;

        &-container {

            &--default {

                .select2-selection--single {
                    height: 73px;

                    font-size: 14px;

                    background-color: transparent;
                    border-color: transparent;
                    border-radius: 0;

                    @include media-breakpoint-down(md) {
                        height: 58px;
                    }
                    
                    @include media-breakpoint-down(sm) {
                        font-size: 12px;
                    }

                    .select2-selection__rendered {
                        padding-left: 35px;
                        padding-right: 55px;

                        line-height: 71px;
                        font-weight: 600;
                        text-transform: uppercase;
                        
                        @include media-breakpoint-down(md) {
                            line-height: 56px;
                        }

                        @include media-breakpoint-down(sm) {
                            padding-left: 15px;
                            padding-right: 35px;
                        }
                    }

                    .select2-selection__arrow {
                        right: 35px;

                        @include media-breakpoint-down(sm) {
                            right: 15px;
                        }
                    }
                }
            }
        }
    }
}