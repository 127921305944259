// -------------------------------------
//   Header
// -------------------------------------

.header {
    position: relative;

    color: #fff;

    background: $primary;
    border-bottom: 1px solid rgba(#fff, 0.2);
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);

    &-line {
        position: relative;

        display: flex;
        align-items: center;
        height: 75px;

        @include media-breakpoint-down(sm) {
            height: 50px;
        }

        &__column {
            flex: 0 0 percentage(1/3);

            &_left {

            }

            &_center {
                text-align: center;
            }

            &_right {
                text-align: right;
            }
        }
    }

    &-logo {
        display: inline-block;
        max-width: 100%;
        padding: 10px 0;

        &__img {
            max-height: 45px;
            max-width: 100px;

            @include media-breakpoint-down(sm) {
                max-height: 35px;
            }
        }
    }

    &__buttons {
        display: inline-flex;
    }

    &-button {
        display: inline-block;
        height: 75px;
        min-width: 75px;
        margin-right: -1px;

        color: #fff !important;
        text-align: center;
        line-height: 75px;
        vertical-align: middle;

        background: transparent;
        border: 1px solid lighten($primary, 10%);
        border-top: 0;
        border-bottom: 0;

        transition: background-color 100ms ease;

        @include media-breakpoint-down(sm) {
            min-width: 50px;
            height: 50px;

            line-height: 50px;
        }

        &__icon {
            display: inline-block;
            width: 20px;
        }

        &:hover {
            background-color: rgba(#fff, 0.1);
        }

        &:active {
            background-color: rgba(#000, 0.1);
        }
    }

    &-drop {
        &__dropdown {
            position: static;
            display: inline-block;

            vertical-align: top;
        }

        &-button {
            display: inline-block;
            height: 75px;
            padding-left: 30px;
            padding-right: 30px;

            line-height: 75px;
            color: #fff !important;

            border-right: 1px solid rgba(#fff, .2);

            text-decoration: none;

            &__burger {
                display: inline-block;
                width: 20px;
                height: 16px;
                margin-right: 20px;

                background: linear-gradient(to bottom, currentColor 2px, rgba(#000, 0) 2px) top left / 1px 7px repeat;

                vertical-align: middle;
            }

            &__text {
                vertical-align: middle;
            }
        }

        &__dropdown-menu {
            top: 100% !important;
            left: 74px !important;
            right: 73px !important;

            margin: 0 !important;
            padding: 40px;

            color: #fff;

            background: $primary;
            border: 0;
            border-top: 1px solid rgba(#fff, .3);
            border-radius: 0;
            box-shadow: 0 13px 32px 0 rgba(0, 0, 0, .2);

            transform: none !important;

            @include media-breakpoint-down(xl) {
                left: 0 !important;
                right: 0 !important;

                padding-left: 30px;
                padding-right: 30px;
            }
        }

        &__row {
            display: flex;
            margin: 0 -40px;

            @include media-breakpoint-down(xl) {
                margin-left: -30px;
                margin-right: -30px;
            }
        }

        &__col {
            // flex: 0 0 15%;
            // max-width: 15%;
			flex: 0 0 16,6666%;
            max-width: 16,6666%;
            width: 100%;
            padding: 0 40px;

            border-right: 1px dotted rgba(#fff, .3);

            @include media-breakpoint-down(xl) {
                padding-left: 30px;
                padding-right: 30px;
            }

            &:last-child {
                border: 0;
            }

            &_contacts {
                flex-basis: 25%;
                max-width: 25%;
            }
        }
    }
}

.header-menu {

    &__title {
        margin-bottom: 10px;

        font-size: 18px;
        text-transform: uppercase;
    }

    &-list {
        padding: 0;

        font-size: 12px;

        list-style: none;

        &__item {
            display: block;
            padding: 5px 0;
        }

        &__link {
            color: rgba(#fff, .65);

            &:hover {
                color: #fff;
            }
        }
    }
}

.header-nav {
    padding: 0;

    font-size: 12px;

    list-style: none;

    &__item {

        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }

    &__link {
        color: #fff !important;

        &:hover {
            opacity: .7;
        }
    }
}

.header-contacts {
    position: relative;

    max-width: 200px;
    // padding-left: 55px;

    font-size: 12px;
    color: inherit;

    &:not(:last-child) {
        margin-bottom: 50px;
    }

    &__title {
        margin-bottom: 10px;

        line-height: 1;
        text-transform: uppercase;
    }

    &__content {
        color: rgba(#fff, .65);

        a {
            color: inherit !important;
        }
    }

    &__icon {
        position: absolute;
        top: 0;
        left: 0;

        width: 35px;
        height: 35px;
        color: inherit;
    }
}

.header-search {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1001;

    display: none;
    width: 100%;

    &.is-active {
        display: block;
    }

    &-line {
        display: flex;
        align-items: center;
        height: 75px;

        background: $primary;

        @include media-breakpoint-down(sm) {
            height: 50px;
        }
    }

    &__input-place {
        position: relative;

        flex: 1;

        height: 100%;
    }

    &__input {
        display: block;
        width: 100%;
        height: 100%;
        padding-left: 30px;

        font-size: 18px;
        color: #fff;

        border: 0 !important;
        box-shadow: none !important;
        outline: none !important;
        background: rgba(#fff, 0) !important;

        @include media-breakpoint-down(sm) {
            font-size: 15px;
        }

        &::placeholder {
            color: #fff;
        }
    }

    &__results {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        overflow: auto;
        max-height: 400px;
        background: #fff;
        box-shadow: 0 2px 10px 0 rgba(0,0,0,.1);

        @include scrollbar-cute;

        &.show {
            display: block;
        }
    }
}

.header-dropdown {
    width: 375px;
    padding: 0;

    border-radius: 12px;
    border: 0;
    box-shadow: 0 5px 30px 0 rgba(#000, .15);

    overflow: hidden;

    &__head {
        position: relative;
        z-index: 1;

        padding: 15px 30px 15px 45px;

        font-size: 18px;
        font-weight: 500;
        color: $dark;

        background: #f7f7f7;
        border-bottom: 1px solid #e6e6e6;

        overflow: hidden;
    }

    &__head-icon {
        position: absolute;
        top: -15px;
        right: 25px;
        z-index: -1;

        width: 90px;
        height: 90px;

        color: rgba(#000, .04);

        transform: rotateZ(-27deg);
    }

    &__body {
        padding: 45px 45px 25px;
    }
}
